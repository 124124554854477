import "../styles/App.scss";

import Homepage from "./HomePage";
import Footer from "./Footer";
import Projects from "./Projects";
import About from "./About";

function App() {
  return (
    <div>
      <Homepage></Homepage>
      <Projects></Projects>
      <div className="slide-3">
        <About></About>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
