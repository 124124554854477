import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-scroll";
import Zoom from "react-reveal/Zoom";
import Jump from "react-reveal/Jump";
import Fade from "react-reveal/Fade";
import "../styles/Homepage.scss";
import ParticleAnimation from "react-particle-animation";
import Navbar from "./Navbar";

const Homepage = () => {
  return (
    <React.Fragment>
      <ParticleAnimation
        className="home-bg"
        numParticles={180}
        background={{ r: 27, g: 28, b: 29, a: 255 }}
      ></ParticleAnimation>
      <Navbar></Navbar>
      <div className="home-page">
        <Zoom>
          <div id="avatar">
            <img src="/naresh.jpeg" />
          </div>
        </Zoom>

        <Fade right>
          <p
            style={{
              color: "white",
              fontSize: "8vmin",
              fontFamily: "'Special Elite', cursive",
            }}
          >
            Naresh Murthy
          </p>
        </Fade>
        <Fade left>
          <div>
            <p
              style={{
                color: "white",
              }}
            >
              Software Developer
            </p>
          </div>
        </Fade>

        <Jump>
          <div className="down-arrow text-light">
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
              to="projects"
              style={{
                fontSize: "3vmin",
              }}
            >
              <span
                style={{
                  fontSize: "3vmin",
                }}
              >
                <Icon name="down arrow" />
              </span>
            </Link>
          </div>
        </Jump>
      </div>
    </React.Fragment>
  );
};
export default Homepage;
