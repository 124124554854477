import React, { useState } from "react";
import { Container, Menu } from "semantic-ui-react";
import { Link } from "react-scroll";
import "../styles/Navbar.scss";
const Navbar = () => {
  let [state, setState] = useState({});

  const handleClick = (e) => setState({ active: e.target.id });

  const { fixed, active } = state;

  let menuItems = ["Home", "Projects", "About"];
  let linkTo;
  return (
    <div className="custom-navbar">
      <Menu
        fixed={fixed ? "top" : null}
        inverted={!fixed}
        pointing={!fixed}
        secondary={!fixed}
        size="large"
      >
        <Container>
          {menuItems.map((curr) => {
            linkTo = curr.toLowerCase();
            return active === curr ? (
              <Menu.Item active={active} key={curr}>
                <Link
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  id={curr}
                  onClick={handleClick}
                  to={linkTo}
                >
                  {curr}
                </Link>
              </Menu.Item>
            ) : (
              <Menu.Item key={curr}>
                <Link
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  id={curr}
                  onClick={handleClick}
                  to={linkTo}
                >
                  {curr}
                </Link>
              </Menu.Item>
            );
          })}
        </Container>
      </Menu>
    </div>
  );
};
export default Navbar;
