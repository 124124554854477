import React from "react";
import { Header } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import "../styles/About.scss";
const About = () => {
  return (
    <div id="about">
      <div className="about-section">
        <Fade down>
          <Header as="h3" style={{ fontSize: "6vmin" }}>
            About me
          </Header>
        </Fade>

        <Zoom right cascade>
          <p
            style={{
              paddingBottom: "5vmin",
              paddingTop: "5vmin",
              textAlign: "center",
            }}
          >
            Hi, I am a<span> Software developer</span> based in
            <span> Bengaluru, India.</span> I have enormous interest in creating
            web applications. My focus is to build web applications which can be
            used by all the<span> users</span> and runs across all the{" "}
            <span> devices.</span>
          </p>
        </Zoom>
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <p style={{ fontSize: "4vmin" }}>
          <span style={{ display: "block" }}>Languages I speak:</span>{" "}
          JavaScript, Node, React, Python, HTML/CSS, SASS
        </p>

        <p style={{ fontSize: "4vmin" }}>
          <span style={{ display: "block" }}>Dev Tools:</span> VSCode, Github
        </p>
      </div>
    </div>
  );
};

export default About;
