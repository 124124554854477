import React from "react";
import "../styles/Footer.scss";
const Footer = () => {
  return (
    <div className="pl-3 text-dark footer">
      <div className="copyrights">
        <p style={{ color: "#5F6368" }}>Built with</p>

        <img src="/react.svg" alt="zeit" width="25px" height="25px"></img>

        <p style={{ color: "#EA4335" }}> by Naresh</p>
      </div>

      <div className="contacts">
        <div>
          <a
            href="https://www.instagram.com/naresh__murthy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/instagram.svg"
              alt="instagram"
              width="35px"
              height="35px"
            ></img>
          </a>
        </div>

        <div>
          <a
            href="https://www.linkedin.com/in/naresh-murthy-451577114/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/linkedin.svg"
              alt="linkedin"
              width="35px"
              height="35px"
            ></img>
          </a>
        </div>
        <div>
          <a
            href="https://github.com/NareshMurthy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/github.svg"
              alt="github"
              width="35px"
              height="35px"
            ></img>
          </a>
        </div>
        <div>
          <a
            href="https://www.npmjs.com/~naresh1105"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/npm.svg" alt="npm" width="60px" height="40px"></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
