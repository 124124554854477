import { Button, Reveal } from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import "../styles/Projects.scss";
const Projects = () => {
  return (
    <div id="projects">
      <div className="cards ">
        <Fade up>
          <div>
            <Reveal animated="move">
              <Reveal.Content visible>
                <div className="visible">
                  <img src="/rrf.svg"></img>
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <div className="hidden">
                  <h4>Firebase, React & Redux</h4>
                  <p>Manage the ever-changing state with Redux</p>
                  <Button
                    color="orange"
                    href="https://github.com/NareshMurthy/React-Firebase-Redux"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Know More
                  </Button>
                </div>
              </Reveal.Content>
            </Reveal>
          </div>
        </Fade>
        <Fade right>
          <div>
            <Reveal animated="move">
              <Reveal.Content visible>
                <div className="visible">
                  <img src="/tensorflow.svg" />
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <div className="hidden">
                  <h4>Machine learning in JS</h4>
                  <p>Use Tensorflow to make the web powerful</p>
                  <Button
                    color="yellow"
                    href="https://github.com/NareshMurthy/TensorFlowJS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Know More
                  </Button>
                </div>
              </Reveal.Content>
            </Reveal>
          </div>
        </Fade>
        <Fade bottom>
          <div>
            <Reveal animated="move">
              <Reveal.Content visible>
                <div className="visible">
                  <img src="/logo-javascript.svg" />
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <div className="hidden">
                  <h4>Data Structures</h4>
                  <p>
                    From O(n^2) to O(logn) - Explore complex algorithms to solve
                    real world problems.
                  </p>
                  <Button
                    // inverted
                    color="black"
                    href="https://github.com/NareshMurthy/Data-Structures-and-algorithms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Know More
                  </Button>
                </div>
              </Reveal.Content>
            </Reveal>
          </div>
        </Fade>
        <Fade left>
          <div>
            <Reveal animated="move">
              <Reveal.Content visible>
                <div className="visible">
                  <img src="/graphql.svg" />
                </div>
              </Reveal.Content>
              <Reveal.Content hidden>
                <div className="hidden">
                  <h4>React-GraphQL</h4>
                  <p>Goodbye Rest API. Hello GraphQL</p>
                  <Button
                    color="pink"
                    href="https://naresh-library-app.herokuapp.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Know More
                  </Button>
                </div>
              </Reveal.Content>
            </Reveal>
          </div>
        </Fade>
      </div>
    </div>
  );
};
export default Projects;
